import React from 'react';

//Style
import './Masthead.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//Libs
import Slider from "react-slick";
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from 'react-scroll';

const Masthead = () => {
	const settings = {
		dots: true,
		infinite: true,
		//autoplay:true,
		slidesToShow: 1,
		slidesToScroll: 1,
		lazyLoad: true
	},
	images = [
		{
			"id": 1,
			"image": "../Images/Carousel/5.jpg",
			"title": "¿Quiénes Somos?",
			"desc": "Somos una organización dedicada a los préstamos personales inmediatos, con más de 15 años de experiencia, enfocada en suplir necesidades económicas de personas y emprendedores costarricenses o residentes de este país."
		},
		{
			"id": 2,
			"image": "../Images/Carousel/3.jpg",
			"title": "Misión",
			"desc": "Ofrecer microcréditos a corto plazo de una forma ágil y oportuna, para cubrir necesidades económicas, con un servicio seguro e innovador."
			//"buttonText": "Ver Requerimientos",
			//"buttonClass": "btn secondary",
			//"buttonHref": "Requirements"
		},
		{
			"id": 3,
			"image": "../Images/Carousel/2.jpg",
			"title": "Visión",
			"desc": "Ser la organización líder en costa rica, en cuanto a microcréditos personalizados de fácil acceso a corto plazo, volviéndonos  el aliado perfecto y viable para la población de este país."
		},
		{
			"id": 4,
			"image": "../Images/Carousel/1.jpg",
			"title": "Solicita tu préstamo en línea",
			"desc": " ",
			"buttonText": "Solicitar mi préstamo",
			"buttonClass": "btn primary",
			"buttonHref": "ContactUs"
		},
	];

	//States
	const [isOnViewport, setisOnViewport] = React.useState('');

	//Methods
	const onChange = (isVisible) => {
		if(isVisible){
			setisOnViewport('animated');
		}
	}
	
	return (
		<div className="masthead">
			<Slider {...settings}>
				{images.map(data => (
					<VisibilitySensor onChange={onChange} key={data.image}>
						<div className={'masthead-tile ' + isOnViewport}>
							<div className="bg">
								<img src={data.image} alt=""/>
							</div>
							<div className="content">
								<div className="left">
									<h2 className="line">
										<span className="line__content">{data.title}</span>
									</h2>
									<p className="fade_in">{data.desc}</p>
									<div className="fade_in">
										{
											data.buttonText?
												<Link className={data.buttonClass} href={`#`+ data.buttonHref} to={data.buttonHref} hashSpy={true} spy={true} smooth={true}>{data.buttonText}</Link>
											:
												''
										}
									</div>
								</div>
							</div>
						</div>
					</VisibilitySensor>
				))}
			</Slider>
		</div>
	);

};

export default Masthead;