import React from 'react';

//Style
import './TermsConditions.scss';

//Libs
import { Element } from 'react-scroll';
import VisibilitySensor from 'react-visibility-sensor';


const TermsConditions = () => {

	//States
	const [isOnViewport, setisOnViewport] = React.useState('');

	//Methods
	const onChange = (isVisible) => {
		if(isVisible){
			setisOnViewport('animated');
		}
	}

	return (
		<Element name="TermsConditions">
			<VisibilitySensor onChange={onChange} partialVisibility={true}>
				<div className={'terms-conditions ' + isOnViewport}>
          <div className="content">
            <h3 className="line very-little">
              <span className="line__content">Aviso Legal</span>
            </h3>
            <div className="fade_in">
              <p>La inscripción de FRAZM Sociedad Anónima, 3-101-000000, ante la Superintendencia General de Entidades Financieras no es una autorización para operar, y la supervisión que ejerce esa Superintendencia es sólo en materia de prevención de legitimación de capitales, financiamiento al terrorismo y financiamiento de la proliferización de armas de destrucción masiva, según lo dispuesto en la Ley N° 7786, "Ley sobre estupefacientes, sustancias psicotrópicas, drogas de uso no autorizado, actividades conexas, legitimización de capitales y financiamiento al terrorismo". Por lo tanto, la SUGEF no supervisa los negocios que ofrece y realiza la empresa, ni su seguridad, estabilidad o solvencia. Las personas que contraten sus productos y servicios lo hacen bajo su cuenta y riesgo.</p>

              <p>Todos Los Derechos Reservados.  2020 © FRAZM S.A.</p>
            </div>
          </div>
				</div>
			</VisibilitySensor>
		</Element>
	);

};

export default TermsConditions;