import React from 'react';

//Style
import './ContactUs.scss';

//Libs
import { Element } from 'react-scroll';
import VisibilitySensor from 'react-visibility-sensor';
import { useForm } from 'react-hook-form';


const ContactUs = () => {

	//States
	const [isOnViewport, setisOnViewport] = React.useState('');

	//Methods
	const onChange = (isVisible) => {
		if(isVisible){
			setisOnViewport('animated');
		}
	}

	const { register, errors, handleSubmit } = useForm({
    mode: "onChange"
  });
  const onSubmit = data => {
    alert(JSON.stringify(data));
  };

	return (
		<Element name="ContactUs">
			<VisibilitySensor onChange={onChange} partialVisibility={true}>
				<div className={'contact-us ' + isOnViewport}>
					<div className="content">
						<div className="two-columns-wrapper reverse">
							<div className="column column-image no-animate">
								<div className="second-content">
									<h3 className="blue line little">
										<span className="line__content">Números de teléfono</span>
									</h3>
									<div className="fade_in">
										<div className="items-list">
											<a href="tel:50622222222" className="item">
												<div className="icon phone">
													<span className="icon-phone"></span>
												</div>
												<div className="text">
													2222-2222
												</div>
											</a>
											<a href="https://api.whatsapp.com/send?phone=50688888888&text=%C2%A1Hola!%20Requiero%20informaci%C3%B3n%20para%20un%20pr%C3%A9stamo." className="item">
												<div className="icon whatsapp">
													<span className="icon-whatsapp"></span>
												</div>
												<div className="text">
													8888-8888
												</div>
											</a>
											<div className="legal-term">
											Cuotas acorde a lo establecido por el Banco Central de Costa Rica para préstamos microcréditos. Ver publicación del BCCR <a href="https://activos.bccr.fi.cr/sitios/bccr/ComunicadosPrensa/Docs_Comunicados_Prensa/CP-BCCR-022-2020-Tasas-anuales-maximas_interes.pdf">aquí</a>. La cuota puede variar ligeramente según el tiempo entre la solicitud del préstamo y fecha de pago de la primera cuota.
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="column column-text">
								<h3 className="limeGreen line little">
									<span className="line__content">Contáctenos</span>
								</h3>
								<div className="fade_in">
									<div className="form">
										<form onSubmit={handleSubmit(onSubmit)}>
											<div className={errors.name? 'input-box error' : 'input-box'}>
												<label htmlFor="name">Nombre completo:</label>
												<input
													name="name"
													placeholder="Nombre completo"
													ref={register({ required: true, min: 5})}
												/>
												{errors.name && <div className="error">Este campo es requerido</div>}
											</div>

											<div className={errors.email? 'input-box error' : 'input-box'}>
												<label htmlFor="email">Correo electrónico:</label>
												<input
													name="email"
													type="email"
													placeholder="Correo electrónico"
													ref={register({ required: true, min: 5, pattern: /^\S+@\S+$/i})}
												/>
												{errors.email && <div className="error">Este campo es requerido</div>}
											</div>

											<div className={errors.number? 'input-box error' : 'input-box'}>
												<label htmlFor="number">Número de teléfono</label>
												<input type="tel" placeholder="0000-0000" name="number" ref={register({ required: true, min: 8 })} />
												{errors.number && <div className="error">Este campo es requerido</div>}
											</div>

											<div className={errors.message? 'input-box error' : 'input-box'}>
												<label htmlFor="message">Mensaje:</label>
												<textarea name="message" placeholder="Mensaje..." ref={register({ required: true, min: 5 })} />
												{errors.message && <div className="error">Este campo es requerido</div>}
											</div>

											<input className="btn" type="submit" value="Enviar" />
										</form>
									</div>
								</div>
							</div>


						</div>
					</div>
				</div>
			</VisibilitySensor>
		</Element>
	);

};

export default ContactUs;