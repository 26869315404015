import React from 'react';

//Styles
import './Global/Styles/Page.scss';

//Components
import Nav from './Components/Nav';
import Masthead from './Components/Masthead';
//import WhoWeAre from './Components/WhoWeAre';
import Requirements from './Components/Requirements';
import Payment from './Components/Payment';
import ContactUs from './Components/ContactUs';
import TermsConditions from './Components/TermsConditions';

function App() {
  return (
    <div className="App">
      <Nav />
      <Masthead />
      <Requirements />
      <Payment />
      <ContactUs />
      <TermsConditions />
    </div>
  );
}

export default App;