import React from 'react';

//Style
import './Requirements.scss';

//Libs
import { Element } from 'react-scroll';
import VisibilitySensor from 'react-visibility-sensor';

const Requirements = () => {

	//States
	const [isOnViewport, setisOnViewport] = React.useState('');

	//Methods
	const onChange = (isVisible) => {
		if(isVisible){
			setisOnViewport('animated');
		}
	}

	return (
		<Element name="Requirements">
			<VisibilitySensor onChange={onChange} partialVisibility={true}>
				<div className={'requirements ' + isOnViewport}>
					<div className="content">
						<div className="two-columns-wrapper">
							<div className="column column-image">
								<div className="inner">
									<div className="bg" style={{backgroundImage: "url('../Images/requirementsSmall.png')", backgroundSize: "36% auto"}}></div>
								</div>
							</div>
							<div className="column column-text">
								<h3 className="limeGreen line">
									<span className="line__content">Requisitos</span>
								</h3>
								<ul className="fade_in">
									<li>Documento de identidad.</li>
									<li>Una referencia personal.</li>
									<li>Una referencia Familiar.</li>
									<li>Dirección de residencia y trabajo.</li>
									<li>Fotocopia de la orden salarial o constancia de Trabajo <strong>(asalariados)</strong>.</li>
									<li>Facturas <strong>(independientes)</strong>.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</VisibilitySensor>
		</Element>
	);

};

export default Requirements;