import React from 'react';

//Style
import './Payment.scss';

//Libs
import { Element } from 'react-scroll';
import VisibilitySensor from 'react-visibility-sensor';

const Payment = () => {

	//States
	const [isOnViewport, setisOnViewport] = React.useState('');

	//Methods
	const onChange = (isVisible) => {
		if(isVisible){
			setisOnViewport('animated');
		}
	}

	return (
		<Element name="Payment">
			<VisibilitySensor onChange={onChange} partialVisibility={true}>
				<div className={'payment ' + isOnViewport}>
					<div className="content">
						<div className="two-columns-wrapper reverse">
							<div className="column column-image">
								<div className="inner">
									<div className="bg" style={{backgroundImage: "url('../Images/payment.jpg')", backgroundSize: "120% auto"}}></div>
								</div>
							</div>
							<div className="column column-text">
								<h3 className="limeGreen line">
									<span className="line__content">Métodos de pago</span>
								</h3>
								<ul className="fade_in">
									<li>Deposito bancarío.</li>
									<li>Sinpe Móvil.</li>
									<li>Nuestro motorizado.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</VisibilitySensor>
		</Element>
	);

};

export default Payment;