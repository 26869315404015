import React from 'react';

//Style
import './Nav.scss';

//Libs
import { Link, animateScroll } from 'react-scroll';

//Images
import OkeyLogo from '../../Global/Images/OkeyLogo.jpg';

const Nav = () => {

	//States
	const [isChecked, setChecked] = React.useState(false);

	//Methods
	const closeNav = (active) => {
		setChecked(active);
	}

	return (
		<nav className="header">
			<div className="content">
				<div className="okey-img">
					<a href="#Home" onClick={()=>{
						animateScroll.scrollToTop();
					}}>
						<img src={OkeyLogo} alt="OKEY" />
					</a>
					<h2 className="logo">Frazm S.A.</h2>
				</div>
				
				<div className="show-menu-btn" onClick={() => closeNav('active')}>
					<span className="icon-menu"></span>
				</div>

				<ul className={"menu " + isChecked}>
					<Link href="#Requirements" onClick={() => closeNav('')} activeClass="active" className="link" to="Requirements" hashSpy={true} spy={true} smooth={true}>Requisitos</Link>
					<Link href="#Payment" onClick={() => closeNav('')} activeClass="active" className="link" to="Payment" hashSpy={true} spy={true} smooth={true}>Métodos de pago</Link>
					<Link href="#ContactUs" onClick={() => closeNav('')} activeClass="active" className="link" to="ContactUs" hashSpy={true} spy={true} smooth={true}>Contáctenos</Link>
					<div className="hide-menu-btn" onClick={() => closeNav('')}>
						<span className="icon-close"></span>
					</div>
				</ul>
			</div>
		</nav>
	);

};

export default Nav;